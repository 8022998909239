/* animation */
@mixin keyFrames($name) {
  /* stylelint-disable-next-line keyframes-name-pattern */
  @keyframes #{$name} {
    @content;
  }
}

/* font */
@mixin fontFace($font-name, $file, $weight) {
  $filepath: "../fonts/" + $file;

  @font-face {
    font: {
      family: $font-name;
      weight: $weight;
    }
    src: url($filepath + ".woff2") format("woff2"), url($filepath + ".woff") format("woff"),
      url($filepath + ".ttf") format("truetype");
  }
}

/* width, height */
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
