$WHITE: #ffffff;

$GRAY1: #222222;
$GRAY2: #484848;
$GRAY3: #888888;
$GRAY4: #ececec;
$GRAY5: #e5e5e5;
$GRAY6: #8c8c8c;

$YELLOW: #ffeac2;

$RED1: #ee3556;
$RED2: #f34257;
