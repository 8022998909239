@use "../mixin" as mixin;
@use "../color" as color;

@include mixin.keyFrames(popup-show) {
  from {
    margin-top: -50px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@include mixin.keyFrames(popup-bg-show) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

iframe {
  @include mixin.size(100%, 100%);
  border: none;
}

.popup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 50%);

  &-body {
    @include mixin.size(606px, 316px);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 32px;
    background-color: color.$WHITE;
    border-radius: 16px;
    transform: translateX(-50%) translateY(-50%);
    animation: popup-show 0.3s;
  }

  &-header {
    @include mixin.size(100%, 28px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    p {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  &-desc {
    margin: 0 0 22px;
    font-size: 12px;
    color: color.$GRAY6;
  }

  &-close {
    font-size: 14px !important;
    font-weight: 400 !important;
    cursor: pointer;
  }
}

.close-btn {
  @include mixin.size(100px, 38px);
  float: right;
  font-size: 14px;
  line-height: 38px;
  color: color.$WHITE;
  text-align: center;
  text-decoration: none;
  background-color: color.$RED2;
  border: none;
  border-radius: 8px;
  outline: none;
}

.open-popup {
  display: flex;
  justify-content: center;
  animation: popup-bg-show 0.3s;
}
