@use "mixin" as mixin;
@use "color" as color;

@include mixin.fontFace(Spoqa Han Sans Neo, SpoqaHanSansNeo-Light, 300);
@include mixin.fontFace(Spoqa Han Sans Neo, SpoqaHanSansNeo-Regular, 400);
@include mixin.fontFace(Spoqa Han Sans Neo, SpoqaHanSansNeo-Medium, 500);
@include mixin.fontFace(Spoqa Han Sans Neo, SpoqaHanSansNeo-Bold, 700);

$FONTFAMILYNAME: #{Spoqa Han Sans Neo};

* {
  box-sizing: border-box;
  font-family: $FONTFAMILYNAME;
}

html,
body {
  @include mixin.size(100%, fit-content);
  min-width: 1440px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  user-select: none;
}

body {
  height: fit-content;
  background: linear-gradient(color.$RED2 0% 30%, color.$GRAY5 30%) no-repeat center center / 100% 100%;
}

article {
  @include mixin.size(75%, auto);
  max-width: 1440px;
  padding: 99px 0 80px;
  margin: 0 auto 92px;
  background-color: color.$WHITE;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 11%);
}

.main {
  @include mixin.size(83.3333%, auto);
  display: flex;
  justify-content: space-between;
  margin: 0 auto 100px;

  img {
    width: 732px;
  }

  &-desc {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding-top: 41px;

    p {
      margin: 0;
    }

    button {
      @include mixin.size(272px, 77px);
      font-size: 24px;
      font-weight: 500;
      line-height: 75px;
      color: color.$WHITE;
      text-align: center;
      cursor: pointer;
      background-color: color.$RED1;
      border: none;
      border-radius: 50px;
      outline: none;
    }

    & + div {
      position: relative;
    }
  }

  &-title {
    margin-bottom: 40px !important;
    font-size: 46px;
    font-weight: 700;
    color: color.$GRAY1;
  }

  &-subtitle {
    margin-bottom: 80px !important;
    font-size: 24px;
    font-weight: 400;
    color: color.$GRAY3;
  }

  &-info {
    @include mixin.size(83.3333%, 403px);
    margin: 0 auto;
  }
}

a {
  img {
    position: absolute;
    right: 0;
    bottom: -70px;
    width: 224px !important;
    cursor: pointer;
  }
}

.info-header {
  @include mixin.size(fit-content, 45px);
  margin: 0 0 40px;
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  color: color.$GRAY2;
  background: linear-gradient(color.$WHITE 0% 50%, color.$YELLOW 50%);
}

@media only screen and (max-width: 1919px) {
  .main {
    img {
      width: 580px;
    }

    &-title {
      font-size: 36px;
    }

    &-subtitle {
      margin-bottom: 40px !important;
      font-size: 16px;
    }

    &-desc {
      button {
        @include mixin.size(180px, 50px);
        font-size: 18px;
        line-height: 50px;
      }
    }
  }
}
