@use "../mixin" as mixin;
@use "../color" as color;

.header {
  @include mixin.size(75%, 120px);
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;

  &-desc {
    @include mixin.size(fit-content, 120px);
    display: flex;
    padding-top: 49px;

    & > * {
      display: block;
      height: 32px;
      line-height: 22px;
      color: color.$WHITE;
      text-decoration: none;
    }

    &:first-of-type {
      padding-top: 38px;
    }

    a {
      margin-right: 40px;
      border-bottom: none;
    }

    img {
      @include mixin.size(107px, 45px);
      margin-right: 17px;
    }

    p {
      margin: 3px 0 0;
      font-size: 18px;
      font-weight: 500;
      color: color.$WHITE;
    }

    span {
      margin-right: 40px;
      border-bottom: 2px solid color.$WHITE;
    }
  }
}
