@use "../mixin" as mixin;
@use "../color" as color;

.step {
  @include mixin.size(32%, 100%);

  &:last-of-type {
    margin: 0;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-img {
    @include mixin.size(100%, 233px);
    position: relative;
    border: 1px solid color.$GRAY4;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 5%);

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
    }
  }

  &-desc {
    display: flex;
    width: 100%;
    margin-top: 37px;

    p {
      margin: 0;

      &:first-child {
        width: 94px;
        margin-right: 24px;
        font-size: 28px;
        font-weight: 700;
        color: color.$GRAY1;
      }

      &:last-child {
        font-weight: 400;
        color: color.$GRAY3;
      }
    }
  }
}

.step1 {
  margin: -48.5px 0 0 -122px;
}

.step2 {
  margin: -68.5px 0 0 -53px;
}

.step3 {
  margin: -50px 0 0 -140px;
}

@media only screen and (max-width: 1919px) {
  a img {
    width: 200px !important;
  }

  .step-desc {
    p {
      &:first-child {
        width: fit-content;
        margin-right: 16px;
        font-size: 20px;
      }

      &:last-child {
        font-size: 14px;
      }
    }
  }

  .step1,
  .step3 {
    width: 228px;
    margin: -35px 0 0 -110px;
  }

  .step2 {
    width: 114px;
  }
}
